import React from "react";
import { Link, navigate } from 'gatsby';

class Nav extends React.Component {

	togglecollapse = (type) => {
		this.props.modalActions.toggle_collapse(type);
	}

	home = () => {
		this.props.modalActions.toggle_menu(false)
		navigate("")
	}

	download = () => {
		this.props.modalActions.toggle_menu(false)
		this.props.modalActions.toggle_modal('download', true)
	}

	render() {
		const { menu, collapse, collapse_type } = this.props.modal;
		const { modalActions } = this.props;

		return (
			<div className="nav">
				<div className="cont_nav">
					<div className="logo" onClick={() => navigate("")}>
						<img src={require('assets/img/Logo_CODM_.png')} alt="" className="img-fluid" />
					</div>
					<ul>
						<li className="dropdown">
							<a>Guide</a>
							<ul className="list">
								<li><Link to="/guide/mode/team_deathmatch">Mode</Link></li>
								<li><Link to="/guide/feature/scorestreak">Fitur Unik</Link></li>
								<li><Link to="/guide/character">Karakter</Link></li>
								<li><Link to="/guide/maps">Maps</Link></li>
								<li><Link to="/guide/weapons/assault_rifle">Senjata</Link></li>
							</ul>
						</li>
						<li className="dropdown">
							<a>Berita</a>
							<ul className="list">
								<li><Link to="/news/all">Semua</Link></li>
								<li><Link to="/news/update">Update</Link></li>
								<li><Link to="/news/event">Event</Link></li>
							</ul>
						</li>
						<li><a href="https://fairplay.codm.garena.co.id/" target="_blank">Fairplay</a></li>
						<li><a href="https://support.garena.co.id" target="_blank">Support</a></li>
						<li className="dropdown">
							<a>Esports</a>
							<ul className="list">
								<li><a href="https://majorseries.codm.garena.co.id/mj10" target="_blank">Major Series</a></li>
								<li><a href="https://codm.co.id/lms24spring" target="_blank">LMS</a></li>
							</ul>
						</li>
						{/* <li>
							<a href="https://majorseries.codm.garena.co.id/majorseries8" target="_blank">Esports</a>
						</li> */}
					</ul>
				</div>
				<div className="navbar_mobile">
					<div className="nav-cont">
						<div className={`hamburger hamburger--spin ${(menu) ? `is-active` : ``}`} onClick={() => modalActions.toggle_menu(!menu)}>
							<span className="hamburger-box">
								<span className="hamburger-inner"></span>
							</span>
						</div>
						<img src={require('assets/img/Logo_CODM_.png')} className="logo" alt="" onClick={() => this.home()} />
						<div className="download" onClick={() => this.download()}>
						</div>
					</div>
					<div className={`overlay-menu ${(menu) ? `active` : ``}`}>
						<div className={`menu ${(menu) ? `active` : ``}`}>
							<ul>
								<li><Link to="/" onClick={() => modalActions.toggle_menu(false)}>Beranda</Link></li>
								<li className={`collapse ${(collapse && collapse_type === 'guide') ? `open` : ``}`}>
									<a onClick={() => this.togglecollapse('guide')}>Guide</a>
									<div className="coll-content">
										<ul className="coll-menu">
											<li><Link to="/guide/mode/team_deathmatch" onClick={() => modalActions.toggle_menu(false)}>Mode</Link></li>
											<li><Link to="/guide/feature/scorestreak" onClick={() => modalActions.toggle_menu(false)}>Fitur Unik</Link></li>
											<li><Link to="/guide/character" onClick={() => modalActions.toggle_menu(false)}>Karakter</Link></li>
											<li><Link to="/guide/maps" onClick={() => modalActions.toggle_menu(false)}>Maps</Link></li>
											<li><Link to="/guide/weapons" onClick={() => modalActions.toggle_menu(false)}>Senjata</Link></li>
										</ul>
									</div>
								</li>
								<li><Link to="/news/all" onClick={() => modalActions.toggle_menu(false)}>Berita</Link></li>
								<li><a href="https://fairplay.codm.garena.co.id/" target="_blank" onClick={() => modalActions.toggle_menu(false)}>Fairplay</a></li>
								<li><a href="https://support.garena.co.id" target="_blank" onClick={() => modalActions.toggle_menu(false)}>Support</a></li>
								<li className={`collapse ${(collapse && collapse_type === 'sosmed') ? `open` : ``}`}>
									<a onClick={() => this.togglecollapse('sosmed')}>Social Media</a>
									<div className="coll-content">
										<ul className="coll-menu">
											<li><a href="" onClick={() => modalActions.toggle_menu(false)}>Facebook</a></li>
											<li><a href="" onClick={() => modalActions.toggle_menu(false)}>Instagram</a></li>
											<li><a href="" onClick={() => modalActions.toggle_menu(false)}>Youtube</a></li>
										</ul>
									</div>
								</li>
							</ul>
						</div>
					</div>

				</div>
			</div>
		);
	}
}

export default Nav;