import React from "react";
import Helmet from "react-helmet";
import config from "data/SiteConfig";
import "assets/scss/index.scss";
import "assets/css/animate.css";
import Nav from 'partials/nav';
import Floating from 'partials/floating';
import Footer from 'partials/footer';
import Popup from "partials/popup";
import SEO2 from "components/SEO/SEO2";

// REDUX
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as modalActions from 'redux/actions/modal';

class MainLayout extends React.Component {
	render() {
		const { modalActions, children } = this.props;
		return (
			<div className="wrapper">
				{/* <div>{window.innerHeight}</div> */}
				<SEO2 />
				<Nav {...this.props}/>
				<Floating actions={modalActions}/>
				{children}
				<Footer/>
				<Popup/>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return { modal: state.modal }
}

function mapDispatchToProps(dispatch) {
	return {
		modalActions: bindActionCreators(modalActions, dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(MainLayout);