import React, { Component } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Helmet from "react-helmet";
import config from "data/SiteConfig";
import {generateJson} from "data/JsonLD";

export default (props) => (
    <StaticQuery 
        query={graphql`
            {
                seo: directusSeo {
                    title: website_title
                    desc: website_description
                    image: website_image
                    fb_app_id: website_fb_id_app
                }
            }
        `}
        render = { data => {
            const { seo } = data;
            const { postSEO } = props;
            let title, description, image, url;

            if (postSEO) {
                title = postSEO.title;
                description = postSEO.description;
                image = postSEO.image
                url = postSEO.url;
            }
            else
            {
                title = seo.title;
                description = seo.desc;
                image = seo.image
                url = config.siteUrl;
            }

            let schemaOrgJSONLD = generateJson({
                url_main: url,
                title_main: title,
                description_main: description,
                image_main: image
            }, postSEO);

            return (
                <Helmet>
                    {/* General tags */}
                    <title>{title}</title>
                    <link rel="shortcut icon" href={require('assets/img/favicon.ico')} type="image/x-icon" />
                    <link rel="icon" href={require('assets/img/favicon.ico')} type="image/x-icon" />
                    <meta name="description" content={description} />
                    <meta name="image" content={image} />

                    {/* Schema.org tags */}
                    <script type="application/ld+json">
                        {JSON.stringify(schemaOrgJSONLD)}
                    </script> 

                    {/* OpenGraph tags */}
                    <meta property="og:url" content={url} />
                    {postSEO ? <meta property="og:type" content="article" /> : null}
                    <meta property="og:title" content={title} />
                    <meta property="og:description" content={description} />
                    <meta property="og:image" content={image} />
                    <meta
                        property="fb:app_id"
                        content={seo.fb_app_id ? seo.fb_app_id : ""}
                    />
                </Helmet>
            );
        }}
    />
)