import React from "react";
import { StaticQuery, graphql } from "gatsby"

export default props => (
	<StaticQuery 
		query={
			graphql`
				{
					settings: directusSetting {
						facebook
						instagram
						youtube
					}
				}
			`
		}
		render = { data => {
			const {actions} = props;
			return (
				<div className="floating">
					<img src={require('assets/img/floating/Tagline_Small.png')} alt="" className="img-fluid"/>
					<ul>
						<li onClick={() => actions.toggle_modal('download', true)} >
							<a href=""><img src={require('assets/img/floating/Button_Download.png')} alt=""/></a>
						</li>
						<li><a href={data.settings.facebook} target="_blank">Facebook</a></li>
						<li><a href={data.settings.instagram} target="_blank">Instagram</a></li>
						<li><a href={data.settings.youtube} target="_blank">Youtube</a></li>
					</ul>
				</div>
			)
		} }
	/>
)
