module.exports = {
	siteTitle: "Garena Call of Duty Mobile", // Site title.
	siteTitleShort: "Garena Call of Duty Mobile", // Short site title for homescreen (PWA). Preferably should be under 12 characters to prevent truncation.
	siteTitleAlt: "Garena Call of Duty Mobile", // Alternative site title for SEO.
	siteLogo: "", // Logo used for SEO and manifest.
	siteUrl: "https://main.codm.garena.co.id/", // Domain of your website without pathPrefix.
	siteImage: "https://web.cdn.id.garenanow.com/webid/CODM/sample/BG.jpg",
	pathPrefix: "/", // Prefixes all links. For cases when deployed to example.github.io/gatsby-advanced-starter/.
	siteDescription: "Garena Call of Duty Mobile", // Website description used for RSS feeds/meta description tag.
	siteRss: "/rss.xml", // Path to the RSS file.
	siteFBAppID: "1397686313654456", // FB Application ID for using app insights
	googleAnalyticsID: "UA-41152417-57", // GA tracking ID.
	dateFromFormat: "DD MMMM YYYY", // Date format used in the frontmatter.
	dateFormat: "DD/MM/YYYY", // Date format for display.
	themeColor: "#000000", // Used for setting manifest and progress theme colors.
	backgroundColor: "#000000", // Used for setting manifest background color.

	guideTab: ["mode", "feature", "character", "maps", "weapons"],
	guideIdTab: ["mode", "fitur unik", "karakter", "map", "senjata"],
	featureTab: ["score_streak", "operation_skill", "lethal", "tactical", "perk"],
	modeTab: ["team_deathmatch", "bomb_mission", "free_for_all", "domination", "battle_royale"],
	weaponsTab: ["assault_rifle", "sniper", "lmg", "smg", "shotgun", "pistol", "melee", "launcher"],
	newsTab: ["all", "update", "event"],
};
