import React, { Component } from 'react';
import Modal from 'react-responsive-modal';
import { StaticQuery, graphql } from 'gatsby';
import Slider from 'react-slick';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
// REDUX
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as modalActions from 'redux/actions/modal';

class Popup extends Component {

    download = (props) =>{
        const {playstore, appstore} = props.settings;
        return(
            <React.Fragment>
                <div className="download__title">
                    Pilih caramu untuk men-download <br/>
                    Garena Call of Duty Mobile
                </div>
                <ul>
                    <li>
                        <a href={playstore} target="__blank" >
                            <img src={require('assets/img/homepage/Button_GP.png')} alt="" className="img-fluid"/>
                        </a>
                    </li>
                    <li>
                        <a href={appstore} target="__blank" >
                            <img src={require('assets/img/homepage/Button_IOS.png')} alt="" className="img-fluid"/>
                        </a>
                    </li>
                </ul>
            </React.Fragment>
        )
    }

    video = (props) =>{
        const {video} = props.settings;
        return(
            <React.Fragment>
                <iframe width="100%" height="100%" src={video} frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen="">
                </iframe>
            </React.Fragment>
        )
    }

    maps = (props) => {
        const {video_link} = props.modal;
        // const settings = {
        //     customPaging: function(i) {
        //         return (
        //             <div>
        //                 <img src={video_link[i].url} alt=""/>
        //             </div>
        //         )
        //     },
        //     dots: true,
        //     dotsClass: "slick-dots slick-thumb",
        //     infinite: true,
        //     speed: 500,
        //     slidesToShow: 1,
        //     slidesToScroll: 1,
        //     autoplay: false
        // };
        
        // if (video_link.length === 0) {
        //     return <div className="not_found">No Image Found</div>
        // }

        return(
            // <Slider {...settings} >
            <Carousel
                infiniteLoop={true}
                showStatus={false}
                showIndicators={false}
                // centerMode={true}
            >
                {video_link.map((data,index) =>
                    <div key={index}>
                        <img src={data.url} alt=""/>
                    </div>
                )}
            </Carousel>
            // {/* </Slider> */}
        )
    }

    render() {
        const { modalActions } = this.props;
        const { is_open, is_type } = this.props.modal;

        return (
            <Modal 
                open={is_open}
                onClose={() => modalActions.toggle_modal(false,false)}
                center={is_type !== 'maps' ? true : false}
                classNames={{
                    overlay: "popup",
                    modal: "popup__modal "+is_type,
                    closeButton: "popup__closeBtn"
                }}
            >
                <div className={`popup__content ${is_type}`}>
                    {(is_type) ? this[is_type](this.props) : ''}
                </div>
            </Modal>
        );
    }
}

function mapStateToProps(state) {
	return { modal: state.modal }
}

function mapDispatchToProps(dispatch) {
	return {
		modalActions: bindActionCreators(modalActions, dispatch)
	};
}

const PopupLayout = connect(mapStateToProps, mapDispatchToProps)(Popup)

export default props => (
    <StaticQuery
        query={
            graphql`
                {
                    settings: directusSetting {
                        playstore
                        appstore
                        video
                    }
                }
            `
        }

        render = { data => 
            <PopupLayout {...data} />
        }
    />
)

// export default connect(mapStateToProps, mapDispatchToProps)(Popup);