import config from 'data/SiteConfig';

let main_json = {
    "@context": "http://schema.org",
    "@type": "VideoGame",
    publisher: "Garena",
    url: config.siteUrl,
    name: config.siteTitle,
    description: config.siteDescription,
    alternateName: config.siteTitleAlt ? config.siteTitleAlt : "",
    genre: ["Shooting", "MMO", "Action"],
    image: config.siteImage,
    screenshot: config.siteImage,
    operatingSystem: [ "Android", "iOS" ],
    applicationCategory: [ "Game", "Mobile application" ],
    aggregateRating: {
        "@type": "AggregateRating",
        "ratingValue": "4.25",
        "reviewCount": "400671"
    },
    offers: {
        "@type": "AggregateOffer",
        "lowPrice": "0.99",
        "highPrice": "99.99",
        "priceCurrency": "USD"
    },
    exampleOfWork: [
        {
            "@type": "VideoGame",
            "name": "Garena Call of Duty Mobile",
            "gamePlatform": [
                "https://en.wikipedia.org/wiki/Android_(operating_system)",
                "https://en.wikipedia.org/wiki/IOS"
            ],
            "contentRating": "ESRB Teen",
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.25",
                "reviewCount": "400671"
            },
            "applicationCategory": "Game, Mobile Application",
            "offers": {
                "@type": "AggregateOffer",
                "lowPrice": "0.99",
                "highPrice": "99.99",
                "priceCurrency": "USD"
            },
            "operatingSystem": [
                "Android",
                "iOS"
            ],
        }
    ]
}

let post_json = {
    "@context": "http://schema.org",
    "@type": "BlogPosting",
    "image": config.siteImage,
    "url": config.siteUrl,
    "headline": config.siteTitle,
    "alternativeHeadline": config.siteTitleAlt,
    "dateCreated": "2019-02-11T11:11:11",
    "dateModified": "2019-02-11T11:11:11",
    "inLanguage": "id-ID",
    "publisher": {
        "@type": "Organization",
        "name": "Garena Indonesia",
        "url": "https://garena.co.id",
        "logo": {
            "@type": "ImageObject",
            "url": "https://www.garena.co.id/img/logo@2x.c05a558a.png",
            "width":"338",
            "height":"97"
        }
    },
    "genre": ["Shooting", "MMO", "Action"],
    "articleSection": "Event",
    "articleBody": config.siteDescription
}

export const generateJson = (mainSEO = false, postSEO = false) => {

    let json = [];
    
    if (mainSEO) {
        const { url_main, title_main, description_main, image_main  } = mainSEO;
        main_json = {
            ...main_json,
            url: url_main,
            name: title_main,
            alternateName: title_main,
            description: description_main,
            image: image_main,
            screenshot: image_main
        }
    }

    json.push(main_json);

    if (postSEO) {
        const { image, url, title, description, date_created, date_modified, type } = postSEO;
        post_json = {
            ...post_json,
            image: image,
            url: url,
            headline: title,
            alternativeHeadline: title,
            dateCreated: date_created,
            dateModified: date_modified,
            articleSection: type,
            articleBody: description
        }
        json.push(post_json);
    }

    return json;
}